export default {
	namespaced: true,
	state: {
		aptosVendors: null,
		productClasses: null,
		aptosColors: null,
		sizeCategories: null,
		sizeRun: null,
		fetchingProductClasses: false,
		fetchingAptosVendors: false,
		fetchingAptosColors: false,
		fetchingSizeCategories: false,
		fetchingSizeRun: false
	},
	mutations: {
		setAptosVendors(state, payload) {
			state.aptosVendors = payload
			state.fetchingAptosVendors = false
		},
		setProductClasses(state, payload) {
			state.productClasses = payload
			state.fetchingProductClasses = false
		},
		setAptosColors(state, payload) {
			state.aptosColors = payload
			state.fetchingAptosColors = false
		},
		setSizeCategories(state, payload) {
			state.sizeCategories = payload
			state.fetchingSizeCategories = false
		},
		setSizeRun(state, payload) {
			state.sizeRun = payload
			state.fetchingSizeRun = false
		},
		setFetchingAptosVendors(state, status) {
			state.fetchingAptosVendors = status
		},
		setFetchingProductClasses(state, status) {
			state.fetchingProductClasses = status
		},
		setFetchingAptosColors(state, status) {
			state.fetchingAptosColors = status
		},
		setFetchingSizeCategories(state, status) {
			state.fetchingSizeCategories = status
		},
		setFetchingSizeRun(state, status) {
			state.fetchingSizeRun = status
		}
	}
}
