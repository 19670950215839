import apiAction from '@/plugins/apiAction'

export default {
	namespaced: true,
	state: {
		data: null,
		dataFetched: false,
		fetchingData: false
	},
	getters: {},
	mutations: {
		setData(state, payload) {
			state.data = payload
		},
		setDataFetched(state, payload) {
			state.dataFetched = payload
		},
		setFetchingData(state, payload) {
			state.fetchingData = payload
		}
	},
	actions: {
		async fetch({ state, commit }, force = false) {
			try {
				if (force || (!state.dataFetched && !state.fetchingData)) {
					commit('setFetchingData', true)
					commit('setData', await apiAction.getProductCount())
					commit('setDataFetched', true)
					commit('setFetchingData', false)
				}
			} catch {
				commit('setFetchingData', false)
				commit('setDataFetched', false)
				commit('setData', null)
			}
		}
	}
}
