import apiAction from '@/plugins/apiAction'

export default {
	namespaced: true,
	state: {
		data: null,
		dataFetched: false,
		fetchingData: false,
		selectedProducts: []
	},
	getters: {
		productsSelected: state => state.selectedProducts.length > 0,
		selectedProducts: state => state.selectedProducts.map(x => state.data[x])
	},
	mutations: {
		setData(state, payload) {
			state.data = payload
		},
		setDataFetched(state, payload) {
			state.dataFetched = payload
		},
		setFetchingData(state, payload) {
			state.fetchingData = payload
		},
		updateSelectedProducts(state, payload) {
			state.selectedProducts = payload
		}
	},
	actions: {
		async fetch({ state, commit }, force = false) {
			try {
				if (force || (!state.dataFetched && !state.fetchingData)) {
					commit('setFetchingData', true)
					commit('setData', await apiAction.getCatalogWorkqueue())
					commit('setDataFetched', true)
					commit('setFetchingData', false)
				}
			} catch {
				commit('setFetchingData', false)
				commit('setDataFetched', false)
				commit('setData', null)
			}
		},
		setProductSelection({ commit }, payload) {
			commit('updateSelectedProducts', payload)
		}
	}
}
