import { createStore } from 'vuex'
import user from '@/store/modules/user'
import enabledProducts from '@/store/modules/enabledProducts'
import availableProducts from '@/store/modules/availableProducts'
import hiddenProducts from '@/store/modules/hiddenProducts'
import productCount from '@/store/modules/productCount'
import dropdowns from '@/store/modules/dropdowns'

export default createStore({
	state: {
		pageTitle: 'Loading...',
		showPageHeader: true,
		maintenance: false
	},
	getters: {
		maintenanceMode: state => state.maintenance
	},
	mutations: {
		setPageHeader(state, status) {
			state.showPageHeader = status
		},
		setPageTitle(state, title) {
			state.pageTitle = title
		},
		setMaintenanceMode(state, status) {
			state.maintenance = status
		}
	},
	actions: {
		fetchAllProducts({ dispatch }) {
			dispatch('enabledProducts/fetch', true)
			dispatch('availableProducts/fetch', true)
			dispatch('hiddenProducts/fetch', true)
			dispatch('productCount/fetch', true)
		}
	},
	modules: {
		user,
		enabledProducts,
		availableProducts,
		hiddenProducts,
		productCount,
		dropdowns
	}
})
