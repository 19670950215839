import axios from '@/plugins/axios'
import { api as config } from '@/config/app.config'
import snackbar from '@/plugins/snackbar'

let headers = {}
const workerHeader = {
	'auth-token': 'FB98EDDE-F302-40FD-82B7-C7B184F98493'
}

class APIClass {
	constructor() {
		this.__setDefaultValues()
		this.__ignoreExceptionByURL = config.ignoreExceptionByURL
		this.__requestIdentifier = false
		this.__toWorker = false
		this.__headers = headers
	}

	successMessage(message) {
		this.__successMessage = message
		this.notifySuccess()
		return this
	}

	retrieveSuccessResponse() {
		this.__sendSuccessResponse = true
		return this
	}

	preventSuccessResponse() {
		this.__sendSuccessResponse = false
		return this
	}

	allowDefaultExceptionMessage() {
		this.__exceptionMessage = true
		return this
	}

	preventDefaultExceptionMessage() {
		this.__exceptionMessage = false
		return this
	}

	notifySuccess() {
		this.__showSuccessMessage = true
		return this
	}

	noSuccessMessage() {
		this.__showSuccessMessage = false
		return this
	}

	noResponse() {
		this.__sendSuccessResponse = false
		this.__showSuccessMessage = false
		this.__exceptionMessage = false
		this.__customExceptionMessage = false
		return this
	}

	toWorker() {
		this.__toWorker = true
		return this
	}

	get(url) {
		return new Promise((resolve, reject) => {
			this.__axiosCall('get', url)
				.then(res => resolve(res))
				.catch(e => reject(e))
		})
	}

	post(url, data) {
		return new Promise((resolve, reject) => {
			this.__axiosCall('post', url, data)
				.then(res => resolve(res))
				.catch(e => reject(e))
		})
	}

	put(url, data) {
		return new Promise((resolve, reject) => {
			this.__axiosCall('put', url, data)
				.then(res => resolve(res))
				.catch(e => reject(e))
		})
	}

	delete(url, data) {
		return new Promise((resolve, reject) => {
			this.__axiosCall('delete', url, data)
				.then(res => resolve(res))
				.catch(e => reject(e))
		})
	}

	__generateRequestIdentifier() {
		let d = new Date().getTime()
		let d2 = (performance && performance.now && performance.now() * 1000) || 0

		this.__requestIdentifier = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
			let r = Math.random() * 16
			if (d > 0) {
				r = (d + r) % 16 | 0
				d = Math.floor(d / 16)
			} else {
				r = (d2 + r) % 16 | 0
				d2 = Math.floor(d2 / 16)
			}

			return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16)
		})
	}

	__axiosCall(method, url, data = false) {
		this.__generateRequestIdentifier()
		const currentIdentifier = this.__requestIdentifier

		if (this.__toWorker) {
			this.__headers = {
				...headers,
				...workerHeader
			}
		}

		return new Promise((resolve, reject) => {
			axios({ method, url, data, headers: this.__headers })
				.then(res => {
					let result = res.data
					this.__successMessageHandler(this.__successMessage || result)
					const responseData = this.__sendSuccessResponse ? result : true
					this.__setDefaultValues(currentIdentifier)
					resolve(responseData)
				})
				.catch(e => {
					this.__exceptionHandler(e)
					this.__setDefaultValues(currentIdentifier)
					reject(e)
				})
		})
	}

	__exceptionHandler(e) {
		const errorCode = e.response ? e.response.status : e
		const messageResponse = e.response ? e.response.data : 'Network Error'
		const urlException = e.response ? e.response?.config?.url : 'Network Error'

		if (
			typeof errorCode === 'number' &&
			typeof messageResponse === 'undefined' &&
			typeof urlException === 'undefined' &&
			errorCode === 0
		) {
			setTimeout(() => {
				window.location.href = ''
			}, 1000)
		}

		if (this.__exceptionMessage && !this.__ignoreExceptionByURL.includes(urlException)) {
			const message = this.__errorCodes(errorCode, messageResponse) || messageResponse
			this.__exceptionMessageHandler(message)
		}
	}

	__successMessageHandler(message) {
		if (this.__showSuccessMessage) {
			snackbar.success(message || 'Success')
		}
	}

	__exceptionMessageHandler(message) {
		if (this.__exceptionMessage) {
			snackbar.error(message)
		}
	}

	__errorCodes(code, message) {
		switch (code) {
			case 0:
				return 'Session timeout. Refreshing page...'
			case 400:
				return message
			case 401:
				return 'Error: Unauthorized request'
			case 403:
				return 'Error: Unauthorized'
			case 405:
				return `Error 405: Invalid request. ${message}`
			case 408:
				return 'Error 408: Request timed out'
			case 422:
				return `Error 422: Unprocessable entity. ${message}`
			case 500:
				return 'Error 500: Internal server error'
			default:
				return false
		}
	}

	__setDefaultValues(currentIdentifier) {
		if (currentIdentifier === this.__requestIdentifier) {
			this.__successMessage = false
			this.__showSuccessMessage = config.showSuccessMessage
			this.__sendSuccessResponse = config.sendSuccessResponse
			this.__exceptionMessage = config.showExceptionMessage
			this.__customExceptionMessage = config.showCustomErrorMessage
			this.__toWorker = false
			this.__headers = headers
		}
	}
}

export const api = new APIClass()

export default api
