import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import { appName } from '../../package.json'

const Home = () => import(/* webpackChunkName: "home-page" */ '@/views/HomePage/HomeView.vue')
const Orders = () => import(/* webpackChunkName: "orders-page" */ '@/views/Orders/OrdersView.vue')
const Products = () => import(/* webpackChunkName: "catalog-workqueue-page" */ '@/views/Products/HomeView.vue')
const Admin = () => import(/* webpackChunkName: "admin-page" */ '@/views/Admin/AdminView.vue')
const PoInvoices = () => import(/* webpackChunkName: "orders-page" */ '@/views/PoInvoices/PoInvoicesView.vue')
const Unauthorized = () => import(/* webpackChunkName: "unauthorized" */ '@/views/UnauthorizedView')
const NotFound = () => import(/* webpackChunkName: "not-found" */ '@/views/NotFoundView')
const Maintenance = () => import(/* webpackChunkName: "maintenance" */ '@/views/MaintenanceView')

const routes = [
	{
		path: `/${appName}/Index`,
		name: 'Home',
		component: Home,
		meta: {
			title: 'Dropship Manager',
			pageHeader: true,
			user: ['admin']
		}
	},
	{
		path: `/${appName}/Orders`,
		name: 'Orders',
		component: Orders,
		meta: {
			title: 'Dropship Manager - Orders',
			pageHeader: true,
			user: ['admin']
		}
	},
	{
		path: `/${appName}/Products`,
		name: 'Products',
		component: Products,
		meta: {
			title: 'Dropship Manager - Products',
			pageHeader: true,
			user: ['admin']
		}
	},
	{
		path: `/${appName}/PoInvoices`,
		name: 'PoInvoices',
		component: PoInvoices,
		meta: {
			title: 'Dropship Manager - PO & Invoices',
			pageHeader: true,
			user: ['admin']
		}
	},
	{
		path: `/${appName}/Admin`,
		name: 'Admin',
		component: Admin,
		meta: {
			title: 'Dropship Manager - Admin',
			pageHeader: true,
			user: ['admin']
		}
	},
	{
		path: `/${appName}/Unauthorized`,
		name: 'Unauthorized',
		component: Unauthorized,
		meta: {
			title: 'Dropship Manager'
		}
	},
	{
		path: `/${appName}/Maintenance`,
		name: 'Maintenance',
		component: Maintenance,
		meta: {
			title: 'Maintenance Mode'
		}
	},
	{
		path: `/${appName}/NotFound`,
		name: 'NotFound',
		component: NotFound,
		meta: {
			title: 'Dropship Manager'
		}
	},
	{
		path: `/${appName}/:pathMatch(.*)*`,
		name: '404',
		component: NotFound,
		meta: {
			title: 'Dropship Manager'
		}
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		return { top: 0, behavior: 'smooth' }
	}
})

router.beforeEach(to => {
	const pageHeader = typeof to.meta.pageHeader === 'undefined' ? true : to.meta.pageHeader
	store.commit('setPageHeader', pageHeader)
	store.commit('setPageTitle', to.meta.title || '')
})

export default router
