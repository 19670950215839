import api from '@/plugins/api'
import apiEndpoints from '@/config/api.endpoints'
import snackbar from '@/plugins/snackbar'

// eslint-disable-next-line
const stringFetch = async (endpoint, jsonError, defaultError) => {
	return await api
		.get(endpoint)
		.then(res => {
			try {
				return JSON.parse(res)
			} catch {
				snackbar.error(jsonError)
				return null
			}
		})
		.catch(err => {
			snackbar.error(`${defaultError} Error: ${err}`)
			return null
		})
}

const jsonFetch = async (endpoint, defaultError) => {
	return await api
		.get(endpoint)
		.then(res => {
			return res
		})
		.catch(err => {
			snackbar.error(`${defaultError} Error: ${err}`)
			return null
		})
}

// eslint-disable-next-line
const jsonPost = async (endpoint, data, defaultError) => {
	return await api
		.post(endpoint, data)
		.then(res => {
			return res
		})
		.catch(err => {
			snackbar.error(`${defaultError} Error: ${err}`)
			return null
		})
}

export default {
	getOrders: async () => await jsonFetch(apiEndpoints.getOrders, 'Failed to get orders'),
	getPoAndInvoices: async () => await jsonFetch(apiEndpoints.getPoAndInvoices, 'Failed to get PO and invoices'),
	getAllProducts: async () => await jsonFetch(apiEndpoints.getAllProducts, 'Failed to get products'),
	getCatalogWorkqueue: async () => await jsonFetch(apiEndpoints.getCatalogWorkqueue, 'Failed to get workQueue'),
	getHiddenProducts: async () => await jsonFetch(apiEndpoints.getHiddenProducts, 'Failed to get hidden products'),
	getProductClassDropdown: async () => await jsonFetch(apiEndpoints.getProductClassDropdown, 'Failed to get product classes'),
	getAptosVendorsDropdown: async () => await jsonFetch(apiEndpoints.getAptosVendorDropdown, 'Failed to get aptos vendors'),
	getAptosSizeCategoryDropdown: async () =>
		await jsonFetch(apiEndpoints.aptosSizeCategoryDropdown, 'Failed to get aptos size categories'),
	getAptosColors: async () => await jsonFetch(apiEndpoints.getAptosColors, 'Failed to get aptos colors'),
	getAptosSizeRun: async payload => await jsonPost(apiEndpoints.getAptosSizeRun, payload, 'Failed to get aptos vendors'),
	getProductCount: async () => await jsonFetch(apiEndpoints.getProductCount, 'Failed to get product count'),
	merchProduct: async payload => await jsonPost(apiEndpoints.merchProduct, payload, 'Failed to update products'),
	toggleProductStateFromWorkQueue: async payload =>
		await jsonPost(apiEndpoints.toggleProductStateFromWorkQueue, payload, 'Failed to update products')
}
