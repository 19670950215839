import { appName } from '../../package.json'
const mainDirectory = appName

export default {
	host: `/`,
	getOrders: `${mainDirectory}/GetOrders`,
	getPoAndInvoices: `${mainDirectory}/GetPoAndInvoices`,
	getAllProducts: `${mainDirectory}/GetAllProducts`,
	getCatalogWorkqueue: `${mainDirectory}/GetCatalogWorkqueue`,
	getHiddenProducts: `${mainDirectory}/GetHiddenProducts`,
	getProductClassDropdown: `${mainDirectory}/GetProductClassDropdown`,
	getAptosVendorDropdown: `${mainDirectory}/GetAptosVendorDropdown`,
	aptosSizeCategoryDropdown: `${mainDirectory}/AptosSizeCategoryDropdown`,
	getAptosColors: `${mainDirectory}/GetAptosColors`,
	getAptosSizeRun: `${mainDirectory}/GetAptosSizeRun`,
	getProductCount: `${mainDirectory}/ProductCount`,
	merchProduct: `${mainDirectory}/MerchProduct`,
	toggleProductStateFromWorkQueue: `${mainDirectory}/ToggleProductStateFromWorkQueue`,
	notificationHub: '/NotificationHub/Connection/WebSocket'
}
