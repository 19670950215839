import user from '@/plugins/user'
import crypto from '@/plugins/crypto'
import { site } from '@/config/app.config'

export default {
	namespaced: true,
	state: {
		email: '',
		account: [],
		store: 0,
		eligibleStores: [],
		user: {
			isSuperAdmin: false,
			isAdmin: false,
			isStore: false
		}
	},
	getters: {
		getEmail: state => state.email,
		getStore: state => state.store,
		getEligibleStores: state => state.eligibleStores,
		isSuperAdmin: state => Boolean(state.account.includes('super')),
		isAdmin: state => Boolean(state.account.includes('admin')),
		isStore: state => Boolean(state.account.includes('store')),
		getAccounts: state => state.account
	},
	mutations: {
		setInitialState(state, payload) {
			state.email = payload.email
			state.account = payload.account
			state.store = payload.store
			state.eligibleStores = payload.eligibleStores
		},
		setUser(state, payload) {
			state.user = payload
		}
	},
	actions: {
		init({ commit, getters }) {
			const propContainer = document.querySelector(site.appContainer)
			const props = { ...propContainer.dataset }

			propContainer.removeAttribute('data-uac')
			propContainer.removeAttribute('data-ad')
			propContainer.removeAttribute('data-mg')
			propContainer.removeAttribute('data-fl')
			propContainer.removeAttribute('data-sa')
			propContainer.removeAttribute('data-sl')
			propContainer.removeAttribute('data-env')
			propContainer.removeAttribute('data-store')
			propContainer.removeAttribute('data-mm')

			const userData = {
				account: []
			}

			if (Object.hasOwnProperty.call('uac', props)) {
				userData.email = crypto.httpDecrypt(props.uac)
			}

			if (Object.hasOwnProperty.call('store', props)) {
				userData.store = crypto.httpDecrypt(props.store)
			}

			if (Object.hasOwnProperty.call('sl', props)) {
				userData.eligibleStores = props.sl !== '' ? crypto.httpDecrypt(props.sl).split(',') : ''
			}

			if (Object.hasOwnProperty.call('ad', props)) {
				if (crypto.httpDecrypt(props.ad) === 'True-4') {
					userData.account.push('admin')
				}
			}

			if (Object.hasOwnProperty.call('eligibleStores', userData)) {
				if (userData.eligibleStores.length === 1 && userData.store && userData.store !== '0') {
					userData.account.push('store')
				}
			}

			//Fake store account -- Does not subscribe to faked store channel
			/*userData.email = '132@dtlr.com'
			userData.store = '132'
			userData.account = ['store']*/

			/*if (userData.store > 0) {
				const emulateStore = 10

				userData.email = `${emulateStore}@dtlr.com`
				userData.store = `${emulateStore}`
				userData.account = ['store']
			}*/

			commit('setInitialState', userData)

			if (Object.hasOwnProperty.call('mm', props)) {
				commit('setMaintenanceMode', crypto.httpDecrypt(props.mm) === 'true', { root: true })
			}

			const userAccess = {
				isSuperAdmin: getters.isSuperAdmin,
				isAdmin: getters.isAdmin,
				isStore: getters.isStore
			}

			commit('setUser', userAccess)

			user.__setUserAccount(userData.account)
		}
	}
}
