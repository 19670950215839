<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const vuex = useStore()
const title = computed(() => vuex.state.pageTitle)
const showPageHeader = computed(() => vuex.state.showPageHeader)
</script>

<template>
	<transition name="slow-fade" mode="out-in">
		<div v-if="showPageHeader">
			<div class="row mx-0 main-page-header-container justify-content-between">
				<div class="col px-0">
					<div>
						<transition name="fade" mode="out-in">
							<h1 class="main-page-header-title">{{ title }}</h1>
						</transition>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<style lang="scss" scoped>
@import 'src/scss/variables';

.main-page-header-container {
	min-height: 80px;
	height: 80px;
	max-height: 80px;
	margin-top: -25px;

	.col,
	.col-auto {
		margin: auto 0;
	}
}

@media only screen and (min-width: $breakpoint-md) {
	.main-page-header-title {
		font-size: 2.4rem;
		font-weight: 500;
	}
}

@media only screen and (max-width: $breakpoint-md) {
	.main-page-header-title {
		font-size: 2.2rem;
		font-weight: 500;
		margin-top: 1rem;
	}
}
</style>
